/*

    Position

*/
.align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center!important;
}

.justify-content-between {
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}

.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.flex-start {
  align-self: flex-start !important;
}

.flex-end {
  align-self: flex-end !important;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}