@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ghdw9f');
  src:  url('fonts/icomoon.eot?ghdw9f#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ghdw9f') format('truetype'),
    url('fonts/icomoon.woff?ghdw9f') format('woff'),
    url('fonts/icomoon.svg?ghdw9f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\e900";
}
.icon-alert-octagon:before {
  content: "\e901";
}
.icon-archive:before {
  content: "\e902";
}
.icon-arrow-down-left:before {
  content: "\e903";
}
.icon-arrow-down-right:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-up-left:before {
  content: "\e908";
}
.icon-arrow-up-right:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-at-sign:before {
  content: "\e90b";
}
.icon-award:before {
  content: "\e90c";
}
.icon-bar-chart:before {
  content: "\e90d";
}
.icon-check-square:before {
  content: "\e90e";
}
.icon-copy:before {
  content: "\e90f";
}
.icon-delete:before {
  content: "\e910";
}
.icon-download:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-help-circle:before {
  content: "\e913";
}
.icon-key:before {
  content: "\e914";
}
.icon-link-2:before {
  content: "\e915";
}
.icon-lock:before {
  content: "\e916";
}
.icon-mail:before {
  content: "\e917";
}
.icon-menu:before {
  content: "\e918";
}
.icon-message-square:before {
  content: "\e919";
}
.icon-package:before {
  content: "\e91a";
}
.icon-paperclip:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-power:before {
  content: "\e91d";
}
.icon-repeat:before {
  content: "\e91e";
}
.icon-save:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-settings:before {
  content: "\e921";
}
.icon-share-2:before {
  content: "\e922";
}
.icon-tag:before {
  content: "\e923";
}
.icon-trash-2:before {
  content: "\e924";
}
.icon-user:before {
  content: "\e925";
}
.icon-x-octagon:before {
  content: "\e926";
}
.icon-x:before {
  content: "\e927";
}
.icon-android:before {
  content: "\e928";
  color: #a4c639;
}
.icon-apple:before {
  content: "\e929";
  color: #999;
}
.icon-bitcoin:before {
  content: "\e92a";
  color: #f7931a;
}
.icon-discord:before {
  content: "\e92b";
  color: #7289da;
}
.icon-facebook:before {
  content: "\e92c";
  color: #4172b8;
}
.icon-gog-dot-com:before {
  content: "\e92d";
  color: #86328a;
}
.icon-googleplay:before {
  content: "\e92e";
  color: #607d8b;
}
.icon-humblebundle:before {
  content: "\e92f";
  color: #cc2929;
}
.icon-instagram:before {
  content: "\e930";
  color: #e4405f;
}
.icon-itch-dot-io:before {
  content: "\e931";
  color: #fa5c5c;
}
.icon-nintendoswitch:before {
  content: "\e932";
  color: #e60012;
}
.icon-oculus:before {
  content: "\e933";
}
.icon-origin:before {
  content: "\e934";
  color: #f56c2d;
}
.icon-patreon:before {
  content: "\e935";
  color: #f96854;
}
.icon-paypal:before {
  content: "\e936";
  color: #00457c;
}
.icon-playstation:before {
  content: "\e937";
  color: #003791;
}
.icon-steam:before {
  content: "\e938";
}
.icon-tumblr:before {
  content: "\e939";
  color: #36465d;
}
.icon-twitch:before {
  content: "\e93a";
  color: #6441a4;
}
.icon-twitter:before {
  content: "\e93b";
  color: #1da1f2;
}
.icon-windows:before {
  content: "\e93c";
  color: #0078d6;
}
.icon-xbox:before {
  content: "\e93d";
  color: #107c10;
}
.icon-youtube:before {
  content: "\e93e";
  color: #f00;
}
