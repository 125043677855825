@media (min-width: $md) {
  body {
    margin-bottom: 0;
    margin-top: 60px;
    height: calc(100% - 60px);
  }
}

.main-nav {
  bottom: 0;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 1;

  @media (min-width: $md) {
    top: 0;
    bottom: unset;
  }
}
