footer {
  background: $black;
  padding-bottom: 60px;

  @media (min-width: $md) {
    padding-bottom: 0;
  }

  .copyright {
    text-align: center;
    margin-bottom: $spacer;
  }

  ul {
    padding-inline-start: 0;
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;

    li {
      flex: auto;
      text-align: center;
    }
  }
}