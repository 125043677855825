/*

    Use this file to set the basic color settings

*/

// Font
//.f-green {
//  color: $green;
//}
//
//.f-blue {
//  color: $blue;
//}

// Background
.bgWhite {
  background-color: $white;
  color: $black;
}

.bg-black {
  background-color: rgba($black, 0.9);
  color: $white;
}

// Shadow
.box-shadow {
  @include box-shadow();
}
