/*

    Here you can find general instructions for the website. Really only commands that don't style the website but commands that appear again and again, in every webproject

*/

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  font-size: 16px;
  -webkit-text-size-adjust: 100%; // for safari landscape orientation bug
  height: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  color: $white;
  font-family: $font-family-regular;
  font-size: $f-standard;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-size: cover;
  background: linear-gradient(to bottom right, $black, $blue, $red, $red-light) fixed;
  background-size: 200% 200%;
  animation: gradientBG 20s ease infinite;
}

/*
  Column set to relative
*/
div[class*="col-"] {
  position: relative;
}

.center {
  align-items: center;
  text-align: center;
}
