.homepage {
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 210px;
    width: 50%;
  }

  .btn {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.37);
  }

  .login-icon {
    display: inline-block;
    font-size: 21px;
    line-height: 21px;
    padding-right: 27px;
    position: relative;
    vertical-align: top;

    &:after {
      background: rgba(225, 225, 225, 0.15);
      content: '';
      height: 36px;
      left: 70%;
      position: absolute;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.37);
      top: -6px;
      width: 1px;
    }
  }
}
